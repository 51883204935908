import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="http://discord.gg/jf5QejXfXg">
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              xmlns="http://www.w3.org/2000/svg">
              <title>Discord</title>
              <path
                d="M13.783 1.311C12.398 0.197 10.208 0.009 10.114 0.002C9.969 -0.011 9.83 0.071 9.77 0.206C9.765 0.214 9.717 0.324 9.664 0.495C10.58 0.65 11.705 0.961 12.723 1.593C12.886 1.693 12.936 1.908 12.836 2.071C12.769 2.178 12.656 2.237 12.539 2.237C12.477 2.237 12.413 2.219 12.356 2.184C10.606 1.098 8.421 1.044 8 1.044C7.579 1.044 5.393 1.098 3.644 2.184C3.481 2.286 3.266 2.235 3.166 2.072C3.064 1.908 3.114 1.695 3.277 1.593C4.295 0.962 5.42 0.65 6.336 0.496C6.283 0.324 6.235 0.215 6.231 0.206C6.17 0.071 6.033 -0.013 5.886 0.002C5.792 0.009 3.602 0.197 2.198 1.326C1.466 2.004 0 5.966 0 9.392C0 9.453 0.016 9.511 0.046 9.564C1.057 11.341 3.817 11.806 4.446 11.826C4.448 11.826 4.452 11.826 4.457 11.826C4.568 11.826 4.673 11.773 4.738 11.684L5.374 10.809C3.658 10.366 2.781 9.613 2.731 9.568C2.587 9.442 2.573 9.222 2.701 9.078C2.827 8.934 3.048 8.92 3.192 9.047C3.212 9.066 4.826 10.435 8 10.435C11.179 10.435 12.793 9.06 12.81 9.047C12.954 8.922 13.173 8.934 13.3 9.079C13.427 9.223 13.413 9.442 13.269 9.568C13.219 9.613 12.342 10.366 10.626 10.809L11.262 11.684C11.327 11.773 11.432 11.826 11.543 11.826C11.548 11.826 11.552 11.826 11.554 11.826C12.183 11.806 14.943 11.341 15.954 9.564C15.984 9.511 16 9.453 16 9.392C16 5.966 14.534 2.004 13.783 1.311ZM5.739 8C5.067 8 4.522 7.378 4.522 6.609C4.522 5.84 5.067 5.218 5.739 5.218C6.412 5.218 6.957 5.84 6.957 6.609C6.957 7.378 6.412 8 5.739 8ZM10.261 8C9.588 8 9.043 7.378 9.043 6.609C9.043 5.84 9.588 5.218 10.261 5.218C10.933 5.218 11.478 5.84 11.478 6.609C11.478 7.378 10.933 8 10.261 8Z" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/MartianM_NFT">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Twitter</title>
              <path
                d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/Martian.Madness.SOL/">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Instagram</title>
              <g>
                <circle cx="12.145" cy="3.892" r="1" />
                <path
                  d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                <path
                  d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;